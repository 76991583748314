.landing-section {
	padding: 10% 0 5%;
	color: #3D95CE;

	.irs-min, .irs-max {
		top: initial;
		bottom: -20px;
    visibility: visible !important;
    color: #7f7f7f;
    background: none;
    font-size: 18px;
    font-weight: bold;
	}
	.irs-from, .irs-to, .irs-single {
		display: none;
	}

	.info-box {
		color: #7f7f7f;
		font-size: 18px;
    font-weight: bold;
		text-align: center;
		padding: 30px 0 0;
		p {
			margin-bottom: 20px;
		}
		p > span {
			font-size: 30px;
			color: #3D95CE;
		}
		.btn-blue {
			background: #3D95CE;
			color: #fff;
			font-size: 16px;
    	font-weight: bold;
		}
		.btn {
			font-size: 24px;
			padding: 20px 30px;
		}
	}
}

.btn-custom {
	border-radius: 0;

	&.btn-blue {
		background: #3D95CE;
		color: #fff;
		font-size: 16px;
  	font-weight: bold;

  	&[disabled] {
  		opacity: 0.3;
  	}
	}
}
.modal-header {
	border: none;
}
.modal-title {
	text-align: center;
	font-size: 24px;
	font-weight: bold;
	color: #3D95CE;
}
.modal-body {
	padding: 15px 15px 0;
}
.modal-content {
	border-radius: 0;
}
.modal-footer {
	border:none;
}
.message-box {
  text-align: center;
  font-size: 14px;
  color: #3d95ce;
  margin: 10px 0;
}
