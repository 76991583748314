.section {
  text-align: center;
  background-color: #fff;
  max-width: 80%;
  margin: 0 auto;
  padding: 100px 0 100px 0;
  
  .container {
	width: 100%;
  }
  
  .empty-container {
	height: 150px;
  }
  
  p {
	margin-bottom: 5px;
  }
  
  .h1 {
	font-size: 36px;
  }
  
  .conversation-center-list {
	list-style: none;
	margin: 2px 0;
	padding: 2px 5px;
	//max-width: 50%;
	width: 50%;
	display: inline-block;
	
	li {
	  display: inline;
	  clear: both;
	  padding: 5px;
	  border-radius: 20px;
	  margin-bottom: 7px;
	}
	
	.left {
	  float: left;
	  display: inline-block;
	  text-align: left;
	  border-radius: 0 20px 20px 0;
	  border-top-left-radius: 20px;
	  color: #3D95CE; //font color
	  border: 0.2px solid #77c5ff; //border color, a bit lighter than font's
	  -webkit-box-shadow: 4px 4px 0 rgba(172, 248, 255, 0.2); //shadow color, a bit lighter than border's
	  box-shadow: 4px 4px 0 rgba(172, 248, 255, 0.2); //shadow color, a bit lighter than border's
	}
	
	.right {
	  float: right;
	  display: inline-block;
	  text-align: right;
	  color: #009C89;
	  border: 0.2px solid #53ceb9;
	  border-radius: 20px 0 0 20px;
	  border-bottom-right-radius: 20px;
	  -webkit-box-shadow: 4px 4px 0 rgba(138, 255, 253, 0.2);
	  box-shadow: 4px 4px 0 rgba(138, 255, 253, 0.2);
	}
  }
  
  .conversation-left-list {
	list-style: none;
	margin: 0;
	padding: 0;
	display: inline-block;
	
	li {
	  list-style: none;
	  float: left;
	  display: inline-block;
	  
	  > img {
		border-radius: 100%;
		width: 96px;
		height: 96px;
		display: block;
	  }
	  
	  > span {
		font-size: 13px;
		color: #3D95CE;
		display: block;
		//margin-bottom: 10px;
	  }
	}
  }
  
  .conversation-right-list {
	list-style: none;
	margin: 0;
	padding: 0;
	display: inline-block;
	
	li {
	  list-style: none;
	  float: right;
	  display: inline-block;
	  
	  > img {
		border-radius: 100%;
		width: 96px;
		height: 96px;
		display: block;
	  }
	  
	  > span {
		font-size: 13px;
		color: #009C89;
		display: block;
	  }
	}
  }
  
  .dwnld-button {
	line-height: 40px;
	display: inline-block;
	color: white;
	border: 0.5px solid #3D95CE;
	text-decoration: none;
	text-align: center;
	padding: 2px 30px;
	border-radius: 20px;
	background-color: #3D95CE;
	box-shadow: 4px 4px 0 rgba(0, 103, 157, 0.2);
	margin: 20px 0 0 0;
  }
  
  .dwnld-button:hover {
	background-color: white;
	color: #3D95CE;
  }
  
  .users-list {
	list-style: none;
	margin: 0;
	padding: 0;
	display: inline-block;
	
	li {
	  list-style: none;
	  //float: left;
	  display: inline-block;
	  
	  > span {
		font-size: 12px;
		color: #3D95CE;
		display: block;
	  }
	  
	  > p {
		font-size: 12px;
		display: block;
	  }
	  
	  a {
		//font-size: 10px;
		> img {
		  //border-radius: 100%;
		  //width: 96px;
		  //height: 96px;
		  display: block;
		  margin: 0 30px 0 30px;
		}
	  }
	}
	
	a.insta-link {
	  > img {
		//border-radius: 100%;
		width: 24px;
		height: 24px;
		display: inline-block;
		//margin: 0 0 20px 0;
	  }
	}
  }
  
  .question-group {
	width: 75%;
	margin: 0 auto;
	
	.panel-title {
	  text-align: left;
	}
	
	.panel-outline {
	  box-shadow: none;
	  border-radius: 0;
	  border-bottom: 1px solid rgba(0, 0, 0, .05);
	  
	  &:first-child {
		border-top: 1px solid rgba(0, 0, 0, .05);
	  }
	}
	
	.panel-body {
	  text-align: left;
	}
  }
}

@media screen and (max-width: 760px) {
  h1 {
	font-size: 22px;
  }
  p br {
	display: none;
  }
  a.navbar-brand {
	padding: 5px 10px;
  }
  .section {
	max-width: 100%;
	
	> .container {
	  margin: 0;
	  padding: 30px 0 30px 0;
	}
	
	p {
	  margin-bottom: 10px;
	  font-size: 14px;
	}

	.conversation-left-list li > img {
	  width: 64px;
	  height: 64px;
	}
	
	.conversation-left-list li > span {
	  font-size: 12px;
	}
	
	.conversation-center-list .left {
	  font-size: 16px;
	}
	
	.conversation-right-list li > img {
	  width: 64px;
	  height: 64px;
	}
	
	.conversation-right-list li > span {
	  font-size: 12px;
	}
	
	.conversation-center-list .right {
	  font-size: 16px;
	}
	
	.users-list {
	  li {
		> span {
		  font-size: 11px;
		}
		
		> p {
		  font-size: 10px;
		}
		
		a {
		  > img {
			width: 64px;
			height: 64px;
			margin: 0 10px 0 10px;
		  }
		}
	  }
	}
	
	.question-group {
	  width: 100%;
	}
	.dwnld-button {
	  margin: 10px 0 0 0;
	}
  }
}
@media screen and (max-width: 480px) {
  h1 {
	margin-top: 10px;
  }
  .section {
	max-width: 100%;
	padding: 30px 0 30px 0; //worked
	> .container {
	  margin: 0;
	  padding: 30px 0 30px 0;
	}
	
	p {
	  margin-bottom: 10px;
	}

	.conversation-left-list li > img {
	  width: 64px;
	  height: 64px;
	}
	
	.conversation-left-list li > span {
	  font-size: 12px;
	}
	
	.conversation-center-list .left {
	  font-size: 16px;
	}
	
	.conversation-right-list li > img {
	  width: 64px;
	  height: 64px;
	}
	
	.conversation-right-list li > span {
	  font-size: 12px;
	}
	
	.conversation-center-list .right {
	  font-size: 16px;
	}
	
	.users-list {
	  li {
		> span {
		  font-size: 12px;
		}
		
		> p {
		  font-size: 12px;
		}
		
		a {
		  > img {
			width: 64px;
			height: 64px;
			margin: 0 10px 0 10px;
		  }
		}
	  }
	}
	
	.question-group {
	  width: 100%;
	}
	.dwnld-button {
	  margin: 10px 0 0 0;
	}
  }
}