header {
    line-height: 40px;
    background: #fff;
    /*position: fixed;*/
    //z-index: 2;
    left: 0;
    right: 0;
    top: 0;
}
.navbar-brand {
	padding: 3rem 0;
	font-weight: bold;
	font-size: 28px;
	text-transform: uppercase;
	span {
		font-weight: normal;
		font-size: 10px;
		display: block;
    	text-align: center;
		color:#212121;
	}
}
.navbar {
	border:1px solid #ededed;
	margin-bottom: 0;
}
.navbar-nav {
	> li {
		> a {
		    padding-bottom: 3rem;
		    padding-top: 3rem;
		    line-height: 27px;
		    vertical-align: middle;
		}
	}
}
.navbar-toggle .icon-bar  {
	background: $black;
}
