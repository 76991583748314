@import "color";

html {
	margin: 0;
	padding: 0;
  height: 100%;
}

body {
	font-family: 'Segoe UI';
	height: 100%;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	margin: 0;
	padding: 0;
	color: #44546A;
	background: $ligth-grey;
}

a {
	color: $blue;
}
